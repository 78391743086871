import React from "react"
import { useTranslation} from 'gatsby-plugin-react-i18next';
import SEO from "../components/seo"
import PageHeader from "../components/PageHeader"
import headerImage from "../images/slider/slide-2.jpg"
import EventList from "../components/EventList"

const IndexPage = () => {
  const { t } = useTranslation()
	
  return (
    <>
      <SEO
        title="Wydarzenia"
        description="Nasza przestrzeń to idealne miejsce dla cyklicznych wydarzeń, meetupów i konferencji. Sprawdż, co w najbliższym czasie będzie się u nas działo!"
      />
      <PageHeader image={headerImage}>
        <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">
					{t('header.eventsTitle')}
        </h1>
        <p data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">
				{t('header.eventsText')}
        </p>
      </PageHeader>
      <EventList />
    </>
  )
}

export default IndexPage
